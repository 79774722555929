// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

function setFocusOnClearFilters() {
  document.removeEventListener('turbo:load', setFocusOnClearFilters)
  const clearButton = document.querySelector<HTMLElement>('[data-target="secret-scanning-filter.clearButton"]')
  if (clearButton === null) {
    return
  }
  const clearButtonHidden = clearButton?.hidden
  if (clearButtonHidden) {
    const openCloseButtons = document.querySelectorAll<HTMLElement>('.js-change-open-close-state')
    const openButton = openCloseButtons[openCloseButtons.length - 2]
    openButton?.focus()
    return
  }
  document.title = document.title.concat(' (filters applied)')
  clearButton.focus()
}

on('click', '.js-change-open-close-state', function () {
  /**
   * The 'turbo:load' event listener handles the case when turbo is enabled- this event is fired
   * "once after the initial page load, and again after every Turbo visit" (https://turbo.hotwired.dev/reference/events)
   */
  document.addEventListener('turbo:load', setFocusOnClearFilters)
})
