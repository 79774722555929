// eslint-disable-next-line no-restricted-imports
import {fire, on} from 'delegated-events'
import {onInput, onKey} from '@github-ui/onfocus'
import type AutocompleteElement from '@github/auto-complete-element'
import type {EditorFromTextArea} from 'codemirror'
import {TemplateInstance} from '@github/template-parts'
import {debounce} from '@github/mini-throttle'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {parseHTML} from '@github-ui/parse-html'
import {GetCharIndexFromBytePosition} from '@github-ui/text'
import {ensureExpanded} from '../behaviors/details'

observe('.js-add-secret-format-button', {
  add() {
    window.postProcessingExpressionCount = 0

    const postProcessingExpressionCountElement = document.querySelector<HTMLElement>(
      '.js-post-processing-expression-count',
    )

    if (postProcessingExpressionCountElement && postProcessingExpressionCountElement.textContent) {
      window.postProcessingExpressionCount = parseInt(postProcessingExpressionCountElement.textContent)
    }
  },
})

on('click', '.js-add-secret-format-button', (event: Event) => {
  const addSecretFormatButton = <HTMLElement>event.currentTarget
  if (!addSecretFormatButton) {
    return
  }

  if (window.postProcessingExpressionCount < getMaxPostProcessingExpressions()) {
    const additionalSecretFormatElements = document.querySelectorAll<HTMLElement>('.js-additional-secret-format')
    if (!additionalSecretFormatElements) {
      return
    }

    // Elements with has-removed-contents are hidden. Find the first available such
    // element and display it.
    for (const element of additionalSecretFormatElements) {
      if (element.classList.contains('has-removed-contents')) {
        element.classList.toggle('has-removed-contents', false)
        window.postProcessingExpressionCount++

        // Hide the 'Add requirements' button if the maximum allowed number of expressions are displayed.
        if (window.postProcessingExpressionCount === getMaxPostProcessingExpressions()) {
          addSecretFormatButton.hidden = true
        }

        break
      }
    }
  }
})

on('click', '.js-remove-secret-format-button', (event: Event) => {
  const addSecretFormatButton = document.querySelector<HTMLElement>('.js-add-secret-format-button')
  if (!addSecretFormatButton) {
    return
  }

  const removeSecretFormatButton = <HTMLElement>event.currentTarget
  if (!removeSecretFormatButton) {
    return
  }

  const additionalSecretFormatElement = removeSecretFormatButton.closest<HTMLElement>('.js-additional-secret-format')!
  if (!additionalSecretFormatElement) {
    return
  }

  // Clear out the expression's input before hiding it, so it shows up as
  // a new input element the next time it is unhidden.
  additionalSecretFormatElement.classList.toggle('has-removed-contents', true)
  const inputElement = additionalSecretFormatElement.getElementsByClassName(
    'js-post-processing-input',
  )[0] as HTMLInputElement
  if (!inputElement) {
    return
  }

  inputElement.value = ''
  const inputElementRules = Array.from(
    additionalSecretFormatElement.getElementsByClassName('js-post-processing-input-rule'),
  )

  // Clear out the error state for the input element.
  const erroredElement = additionalSecretFormatElement.getElementsByClassName('errored')[0] as HTMLElement
  if (erroredElement) {
    erroredElement.classList.toggle('errored', false)
  }
  // Remove temporary post-processing elements from form
  for (const element of inputElementRules) {
    document.getElementById(`${element.id}_hidden`)?.remove()
  }
  document.getElementById(`${inputElement.id}_hidden`)?.remove()

  debouncedTestPatternMatches(window.codeEditor.getValue())
  window.postProcessingExpressionCount--

  // Re-display 'Add requirement' button if the number of displayed inputs is under the limit.
  if (window.postProcessingExpressionCount < getMaxPostProcessingExpressions()) {
    addSecretFormatButton.hidden = false
  }
})

observe('.js-test-code', {
  async add() {
    const testCodeTextArea = document.querySelector('.js-test-code') as HTMLTextAreaElement
    if (!testCodeTextArea) {
      return
    }

    const editorHeight = testCodeTextArea.clientHeight

    const CodeMirror = await import('codemirror')
    if (!CodeMirror) {
      return
    }

    window.codeEditor = CodeMirror.default.fromTextArea(testCodeTextArea, {
      lineNumbers: false,
      lineWrapping: true,
      mode: 'text/x-yaml',
      inputStyle: 'contenteditable',
      value: testCodeTextArea.value,
      lineSeparator: '\r\n',
      theme: 'github-light',
    })

    if (editorHeight !== 0) {
      const element = document.querySelector('.CodeMirror') as HTMLElement

      if (element) {
        element.style.height = `${editorHeight}px`
        element.style.border = '1px solid #e1e4e8'
        element.style.borderRadius = '6px'
      }
    }

    window.codeEditor.save()

    const testCustomPatternForm = document.querySelector<HTMLFormElement>('.js-test-custom-secret-scanning-pattern')!
    if (!testCustomPatternForm) {
      return
    }

    let debouncedApiCall = debouncedTestPatternMatches
    if (testCustomPatternForm.hasAttribute('data-source-is-readonly')) {
      debouncedApiCall = debouncedTestPatternMatchesReadOnly
    }

    window.codeEditor.on('change', () => {
      debouncedApiCall(window.codeEditor.getValue())
    })
  },
})

on('change', '.js-post-processing-input-rule', async function () {
  if (!/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    return
  }
  if (!window.codeEditor) {
    return
  }
  debouncedTestPatternMatches(window.codeEditor.getValue())
})

onInput('.js-custom-secret-scanning-pattern-form *', async function () {
  if (!window.codeEditor) {
    return
  }
  debouncedTestPatternMatches(window.codeEditor.getValue())
})

on('click', '.js-repo-selector-dialog-summary-button.disabled', (event: Event) => {
  // Summary buttons cannot be disabled directly. We add a `disabled` class to the button, but
  // the button is still clickable, so we need to short-circuit that and prevent default action.
  event.preventDefault()
})

// Clear out error state when user starts typing in the input.
onInput('.js-description-input', async function () {
  const descriptionInput = document.querySelector<HTMLInputElement>('.js-description-input')
  if (!descriptionInput) {
    return
  }

  removeErrorStylingFromInput(descriptionInput.parentElement!)
  const errorMessageId = descriptionInput.getAttribute('aria-describedby')
  if (!errorMessageId) {
    return
  }

  document.querySelector<HTMLElement>(`#${errorMessageId}`)?.remove()
})

// Click handler for copy buttons on AI-generated expressions.
on('click', '.js-generated-expression-use', async (event: Event) => {
  const secretFormatInputField = document.querySelector<HTMLInputElement>('.js-secret-format')
  if (!secretFormatInputField) {
    return
  }

  const examplesTextArea = document.querySelector<HTMLTextAreaElement>('.js-generate-expression-examples')
  if (!examplesTextArea) {
    return
  }

  const copiedExpression = event.currentTarget as HTMLElement
  if (!copiedExpression) {
    return
  }

  const copiedExpressionTextElementId = copiedExpression.attributes.getNamedItem('for')?.value
  const copiedExpressionTextElement = document.getElementById(copiedExpressionTextElementId!)
  if (!copiedExpressionTextElement) {
    return
  }

  secretFormatInputField.value = copiedExpressionTextElement.textContent!

  // Populate `Test strings` form with examples
  window.codeEditor.setValue(examplesTextArea.value.replaceAll(`\n`, ` `))

  secretFormatInputField.focus()
})

on('click', '.js-generate-expressions-form-submit-button', async (event: Event) => {
  event.preventDefault()

  // Error handling for description input
  const descriptionInput = document.querySelector<HTMLInputElement>('.js-description-input')
  if (!descriptionInput) {
    return
  }

  if (descriptionInput.value === '') {
    addErrorStylingToInput(descriptionInput.parentElement!)

    // eslint-disable-next-line i18n-text/no-en
    setErrorMessageForInput(descriptionInput, 'description_empty', 'Field cannot be blank')

    return
  }

  const generatedExpressionsSection = document.querySelector('.js-generated-expressions-section') as HTMLElement
  if (!generatedExpressionsSection) {
    return
  }

  const errorSection = document.querySelector('.js-generated-expressions-error-section') as HTMLElement
  if (!errorSection) return

  const warningSection = document.querySelector('.js-generated-expressions-warning-section') as HTMLElement
  if (!warningSection) return

  generatedExpressionsSection.hidden = true
  errorSection.hidden = true
  warningSection.hidden = true

  const generatedExpressionBoxElements = document.querySelectorAll('.js-generated-expression-box')
  if (!generatedExpressionBoxElements) {
    return
  }

  // Hide all generated expression boxes
  for (let i = 0; i < generatedExpressionBoxElements.length; i++) {
    generatedExpressionBoxElements[i]!.toggleAttribute('hidden', true)
  }

  const submitButton = getSubmitButton(event)
  if (!submitButton) {
    return
  }

  // Manually set `disable-with`, or loading behavior for buttons once clicked. This prevents users from clicking a button rapidly multiple times.
  setSubmitButtonDisableWith(submitButton)

  const form = getGenerateExpressionsForm()
  if (!form) return

  let data
  try {
    const response = await fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {Accept: 'application/json'},
    })

    data = await response.json()

    if (data.error_msg) {
      data = await response.json()

      errorSection.textContent = data.error_msg
      errorSection.hidden = false
      resetSubmitButton(submitButton)

      return
    }
  } catch (e) {
    // network and unhandled client errors

    // eslint-disable-next-line i18n-text/no-en
    errorSection.textContent = 'Something went wrong. Please try again later.'
    errorSection.hidden = false
    resetSubmitButton(submitButton)

    return
  }

  // Populate the generated expressions in the results section.
  if (data && data.generated_expressions) {
    if (data.generated_expressions.length === 0) {
      // eslint-disable-next-line i18n-text/no-en
      warningSection.textContent = 'No expressions were generated. Please retry with a different description.'
      warningSection.ariaLabel = warningSection.textContent
      warningSection.role = 'alert'
      warningSection.hidden = false
    } else {
      const generatedExpressionElements = document.querySelectorAll('.js-generated-expression')
      if (!generatedExpressionElements) {
        return
      }

      const generatedExpressionExplanationElements = document.querySelectorAll('.js-generated-expression-explanation')
      if (!generatedExpressionExplanationElements) {
        return
      }

      // Set generated expressions and explanations
      for (let i = 0; i < data.generated_expressions.length; i++) {
        generatedExpressionElements[i]!.textContent = data.generated_expressions[i].regex
        generatedExpressionExplanationElements[i]!.textContent = data.generated_expressions[i].explanation
      }

      // Clear out the remaining elements that don't have an expression mapped to them.
      for (let i = data.generated_expressions.length; i < generatedExpressionElements.length; i++) {
        generatedExpressionElements[i]!.textContent = ''
        generatedExpressionExplanationElements[i]!.textContent = ''
      }

      // Display elements that have expressions
      for (let i = 0; i < data.generated_expressions.length; i++) {
        generatedExpressionBoxElements[i]!.removeAttribute('hidden')
      }

      errorSection.hidden = true
      warningSection.hidden = true
      generatedExpressionsSection.hidden = false
    }
  }

  resetSubmitButton(submitButton)
})

on(
  'click',
  '.js-save-and-dry-run-button, .js-custom-pattern-submit-button, .js-org-repo-selector-dialog-dry-run-button',
  (event: Event) => {
    event.preventDefault()

    const submitButton = getSubmitButton(event)
    if (!submitButton) {
      return
    }

    // Manually set `disable-with`, or loading behavior for buttons once clicked. This prevents users from clicking a button rapidly multiple times.
    setSubmitButtonDisableWith(submitButton)

    const customPatternForm = getCustomPatternForm()
    if (!customPatternForm) return

    // Ensure we are accounting for users queueing dry runs after edit.
    if (
      submitButton.className.includes('js-save-and-dry-run-button') ||
      submitButton.className.includes('js-org-repo-selector-dialog-dry-run-button')
    ) {
      createHiddenInputField(customPatternForm, 'submit_type', 'save_and_dry_run')
    }

    // Ensure form submit events are triggered. form.submit() does not work here since it directly submits the form without handling custom behavior.
    fire(customPatternForm, 'submit')
  },
)

function getSubmitButton(event: Event): HTMLButtonElement {
  return <HTMLButtonElement>event.currentTarget
}

function setSubmitButtonDisableWith(submitButton: HTMLButtonElement) {
  submitButton.textContent = submitButton.getAttribute('data-disable-with') || ''
  submitButton.disabled = true
}

function resetSubmitButton(submitButton: HTMLButtonElement) {
  submitButton.textContent = submitButton.getAttribute('data-original-text') || ''
  submitButton.disabled = false
}

function getCustomPatternForm(): HTMLFormElement | null {
  return document.querySelector<HTMLFormElement>('.js-custom-secret-scanning-pattern-form')
}

function getGenerateExpressionsForm(): HTMLFormElement | null {
  return document.querySelector<HTMLFormElement>('.js-generate-expressions-form')
}

const createHiddenInputField = (form: HTMLFormElement, name: string, value: string) => {
  const hiddenInput = document.createElement('input')
  hiddenInput.type = 'hidden'
  hiddenInput.name = name
  hiddenInput.id = `${name}_hidden`
  hiddenInput.value = value

  form.appendChild(hiddenInput)
  hiddenInput.required = true
}

// Debounce reaction to form changes by 300ms to avoid overload on service, and updates causing the highlighted matches to flash.
const debouncedTestPatternMatches = debounce(function (testCode: string) {
  const customPatternSubmitButton = document.querySelector<HTMLElement>('.js-custom-pattern-submit-button')
  const saveAndDryRunButton = document.querySelector<HTMLElement>('.js-save-and-dry-run-button')
  const repoSelectorDialogSummaryButton = document.querySelector<HTMLElement>('.js-repo-selector-dialog-summary-button')

  const editPatternMessageElement = document.querySelector<HTMLElement>('.js-update-pattern-info')

  const patternMatchesCountElement = document.querySelector<HTMLElement>('.js-test-pattern-matches')!
  if (!patternMatchesCountElement) return

  if (testCode.length === 0) {
    const dryRunStatusElement = document.querySelector<HTMLElement>('.js-dry-run-status')!
    if (!dryRunStatusElement) return

    // Don't disable if form is in a state where we can cancel dry run
    if (!allowDryRunCancellation(dryRunStatusElement)) {
      customPatternSubmitButton?.setAttribute('disabled', 'true')
    }

    // Do not query when the test string is empty.
    saveAndDryRunButton?.setAttribute('disabled', 'true')
    repoSelectorDialogSummaryButton?.classList.add('disabled')
    patternMatchesCountElement.textContent = ''
  } else {
    // Persist changes in test string back to the rails form version.
    window.codeEditor.save()

    const testCustomPatternForm = document.querySelector<HTMLFormElement>('.js-test-custom-secret-scanning-pattern')!
    if (!(testCustomPatternForm instanceof HTMLFormElement)) return

    const customPatternForm = getCustomPatternForm()
    if (!customPatternForm) return

    // Duplicate the form into an invisible hidden-input "test" form, for validating matches and clear old test form if exists.
    for (const element of customPatternForm.elements) {
      if (element instanceof HTMLInputElement && element.name) {
        if (element.type === 'text' || (element.type === 'radio' && element.checked)) {
          const hiddenElement = document.getElementById(`${element.name}_hidden`) as HTMLInputElement
          if (hiddenElement !== null) {
            hiddenElement.remove()
          }
          createHiddenInputField(testCustomPatternForm, element.name, element.value)
        }
      }
    }

    patternMatchesCountElement.textContent = ' - Finding matches..'

    updatePatternMatches(
      testCustomPatternForm,
      getTestErrorHandler(
        customPatternForm,
        customPatternSubmitButton,
        saveAndDryRunButton,
        repoSelectorDialogSummaryButton,
        editPatternMessageElement,
      ),
      getTestLabelUpdater(patternMatchesCountElement),
    )
  }
}, 300)

const getTestLabelUpdater = (patternMatchesCountElement: HTMLElement) => (matchesJson: string[]) => {
  if (matchesJson.length === 0) {
    patternMatchesCountElement.textContent = ' - No matches'
  } else if (matchesJson.length === 1) {
    patternMatchesCountElement.textContent = ' - 1 match'
  } else {
    // Remove duplicate matches from the list.
    const serializedArray = []
    for (const m of matchesJson) {
      serializedArray.push(JSON.stringify(m))
    }
    const serializedArrayAsSet = new Set(serializedArray)
    const uniqueSerializedArray = [...serializedArrayAsSet]
    patternMatchesCountElement.textContent = ` - ${uniqueSerializedArray.length} matches`
  }
}

/**
 * Creates a handler that receives an error or null and updates ui elements based on error content.
 * @param customPatternSubmitButton Button to enable/disable based on error received.
 * @returns true if no error received.
 */
const getTestErrorHandler =
  (
    form: HTMLFormElement,
    customPatternSubmitButton: HTMLElement | null,
    saveAndDryRunButton: HTMLElement | null,
    repoSelectorDialogSummaryButton: HTMLElement | null,
    editPatternMessageElement: HTMLElement | null,
  ) =>
  (error?: ErrorWithMessage) => {
    clearInputErrorState(form)

    if (error?.message) {
      customPatternSubmitButton?.setAttribute('disabled', 'true')
      saveAndDryRunButton?.setAttribute('disabled', 'true')
      repoSelectorDialogSummaryButton?.classList.add('disabled')
      if (editPatternMessageElement) {
        // Also an indirect check for whether we are in Edit mode on the form.
        editPatternMessageElement.hidden = true
      } else {
        // In Create mode, Expand the "More options" section if there are errors relating to form elements within it.
        // In Edit mode, the section is expanded by default.
        if (
          error?.error_type === 'START_DELIMITER' ||
          error?.error_type === 'END_DELIMITER' ||
          error?.error_type === 'MUST_MATCH' ||
          error?.error_type === 'MUST_NOT_MATCH'
        ) {
          const detailsToggle = document.querySelector<HTMLElement>('.js-more-options.js-details-container')!
          if (detailsToggle) {
            ensureExpanded(detailsToggle)
          }
        }
      }

      showInputErrorState(form, error)

      return false
    } else {
      const modeElement = document.querySelector<HTMLElement>('.js-mode')!
      if (!modeElement) {
        return false
      }

      const dryRunStatusElement = document.querySelector<HTMLElement>('.js-dry-run-status')!
      if (!dryRunStatusElement) {
        return false
      }

      /**
       * There are 2 situations in which the submit button needs to be enabled.
       * 1) When the pattern has unpublished changes, and the dry run is cancelled or skipped. In this case,
       *    the button displays `Save and dry run`.
       * 2) When the pattern is being created or updated after publishing. In this case,
       *    the button displays `Save and dry run` and `Publish changes`
       */
      if (
        dryRunStatusElement.textContent?.toLowerCase() === 'cancelled' ||
        dryRunStatusElement.textContent?.toLowerCase() === 'skipped' ||
        !(
          modeElement.textContent?.toLowerCase() === 'unpublished' ||
          modeElement.textContent?.toLowerCase() === 'published'
        )
      ) {
        customPatternSubmitButton?.removeAttribute('disabled')
      }

      repoSelectorDialogSummaryButton?.classList.remove('disabled')
      saveAndDryRunButton?.removeAttribute('disabled')
      if (editPatternMessageElement) {
        editPatternMessageElement.hidden = false
      }
      return true
    }
  }

function showInputErrorState(form: HTMLFormElement, error: ErrorWithMessage) {
  if (error.error_type === 'MUST_MATCH' || error.error_type === 'MUST_NOT_MATCH') {
    let idx = 0
    // Get PPE input groups
    const postProcessingExpressions = form.getElementsByClassName('js-additional-secret-format')
    for (const expressionContainer of postProcessingExpressions) {
      if (idx > (error.error_index || 0)) {
        // Something weird happened. dont mark errors
        return
      }
      const radioButtons = expressionContainer.getElementsByTagName('input')
      const checkedButton = [...radioButtons].filter(x => x.checked)
      const type = checkedButton && checkedButton[0]?.value.toUpperCase()
      // Error_index is based on the group of same type of ppe fields (must vs must_not)
      const isErrorField = type === error.error_type && idx === error.error_index
      const inputs = expressionContainer.getElementsByTagName('input')
      const expressionInput = [...inputs].filter(x => x.type === 'text')
      if (!expressionInput || expressionInput.length === 0) {
        // Something weird happened, we dont have a text input
        continue
      }
      const input = expressionInput[0]!
      if (input.value === '') {
        // empty inputs are not included in the test verification request so input indexes will be off
        continue
      }
      if (isErrorField) {
        const errorInputID = input.id
        if (input && input.parentElement) {
          addErrorStylingToInput(input.parentElement)
          setErrorMessageForInput(input, errorInputID, error.message, true, 'mt-6')
        }
        return
      } else if (type === error.error_type) {
        idx++
      }
    }
  } else {
    const errorInputID = errorTypeToInputId[error.error_type]
    const input = document.querySelector<HTMLElement>(`#${errorInputID}`)
    if (input && input.parentElement) {
      addErrorStylingToInput(input.parentElement)
      setErrorMessageForInput(input, errorInputID!, error.message, true)
    }
  }
}

function clearInputErrorState(form: HTMLFormElement) {
  const errorBanner = document.querySelector<HTMLElement>('.js-error-banner')!
  errorBanner.hidden = true
  for (const input of form.getElementsByTagName('input')) {
    if (input.parentElement?.classList.contains('errored')) {
      removeErrorStylingFromInput(input.parentElement)
      const errorMessageId = input.getAttribute('aria-describedby')
      document.querySelector<HTMLElement>(`#${errorMessageId}`)?.remove()
    }
  }
}

function getMaxPostProcessingExpressions() {
  const defaultMaxPostProcessingExpressions = 10

  const maxPostProcessingExpressionsElement = document.querySelector<HTMLElement>(
    '.js-post-processing-expression-max-count',
  )!

  if (!maxPostProcessingExpressionsElement) {
    return defaultMaxPostProcessingExpressions
  }

  const maxExpressionsAsString = maxPostProcessingExpressionsElement.textContent

  if (!maxExpressionsAsString) {
    return defaultMaxPostProcessingExpressions
  }

  return parseInt(maxExpressionsAsString)
}

function addErrorStylingToInput(inputElement: HTMLElement) {
  // Adds a red border and an error popup to the element. Since `form-group` adds
  // a vertical margin, we manually offset it using my-0 (margin-y: 0)
  inputElement?.classList.add('form-group', 'errored', 'my-0')
}

function removeErrorStylingFromInput(inputElement: HTMLElement) {
  inputElement?.classList.remove('form-group', 'errored', 'my-0')
}

function setErrorMessageForInput(
  inputElement: HTMLElement,
  errorID: string,
  errorMessage: string,
  setErrorMargin: boolean = false,
  errorMargin: string = 'mt-4',
) {
  const message = document.createElement('p')
  const messageID = `${errorID}_error_message`
  message.classList.add('note', 'error')
  if (setErrorMargin) {
    message.classList.add(errorMargin)
  }
  message.id = messageID
  message.textContent = errorMessage
  inputElement.setAttribute('aria-describedby', messageID)
  inputElement.insertAdjacentElement('afterend', message)

  // Set focus on the input element for screen reader users
  inputElement.focus()
}

// Removes code highlights if any.
function clearCodeHighlights() {
  if (!window.codeEditor) return

  const from = window.codeEditor.posFromIndex(0)
  const to = window.codeEditor.posFromIndex(window.codeEditor.getValue().length)

  for (const mark of window.codeEditor.findMarks(from, to)) {
    mark.clear()
  }
}

function allowDryRunCancellation(dryRunStatusElement: HTMLElement) {
  return (
    dryRunStatusElement.textContent?.toLowerCase() === 'queued' ||
    dryRunStatusElement.textContent?.toLowerCase() === 'inprogress'
  )
}

const errorTypeToInputId: {[key: string]: string} = {
  NONE: '',
  CONFIG_LOAD: 'secret_format',
  COMPILE_DB: 'secret_format',
  START_DELIMITER: 'before_secret',
  END_DELIMITER: 'after_secret',
  DISPLAY_NAME: 'display_name',
  DB_SIZE: 'secret_format',
  DB_SIZE_CALCULATION: 'secret_format',
}
interface ErrorWithMessage {
  message: string
  error_type: string
  error_index?: number
}

// Queries the token scanning service to determine if the current test string matches the pattern
async function updatePatternMatches(
  form: HTMLFormElement,
  responseCallback: (error: ErrorWithMessage) => boolean,
  updateLabelCallback: (matchesJson: string[]) => void,
) {
  // Query service for matches.
  let data
  try {
    const response = await fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {Accept: 'application/json'},
    })
    if (response.ok) {
      data = await response.json()
    }
  } catch (e) {
    // ignore network errors
  }

  if (data) {
    if (responseCallback(data.error)) {
      if (data.has_matches) {
        const matchesJson = JSON.parse(data.matches)
        clearCodeHighlights()
        updateLabelCallback(matchesJson)
        // Highlight matches
        for (const match of matchesJson) {
          MarkMatch(window.codeEditor, match.start, match.end)
        }
      } else {
        updateLabelCallback([])
        clearCodeHighlights()
      }
    }

    toggleWildcardsWarning(data.has_wildcard_warning)
  }
}

const debouncedTestPatternMatchesReadOnly = debounce(function (testCode: string) {
  const testCustomPatternForm = document.querySelector<HTMLFormElement>('.js-test-custom-secret-scanning-pattern')!
  if (!(testCustomPatternForm instanceof HTMLFormElement)) return

  const patternMatchesCountElement = document.querySelector<HTMLElement>('.js-test-pattern-matches')!
  if (!patternMatchesCountElement) return

  if (testCode.length === 0) {
    // Do not query when the test string is empty.
    patternMatchesCountElement.textContent = ''
  } else {
    if (!window.codeEditor) return

    window.codeEditor.save()
    updatePatternMatches(testCustomPatternForm, () => true, getTestLabelUpdater(patternMatchesCountElement))
  }
}, 300)

// MarkMatch highlights the matches coming back from Hypercredscan in the code editor,
// taking into account converting UTF16 compatibility
export function MarkMatch(codeEditor: EditorFromTextArea, start: number, end: number) {
  const contents = codeEditor.getValue()
  start = GetCharIndexFromBytePosition(contents, start)
  end = GetCharIndexFromBytePosition(contents, end)

  if (start === -1 || end === -1) return

  const from = codeEditor.posFromIndex(start)
  const to = codeEditor.posFromIndex(end)
  codeEditor.markText(from, to, {className: 'text-bold hx_keyword-hl rounded-2 d-inline-block'})
}

declare global {
  interface Window {
    codeEditor: EditorFromTextArea
    postProcessingExpressionCount: number
  }
}

// Handles repo selection in the save and dry run dialog for org dry runs

async function removeDryRunRepo(event: CustomEvent) {
  const form = event.currentTarget as HTMLFormElement
  event.preventDefault()
  updateDryRunSelectedRepos(form, parseInt(form.remove_repo_id.value), false)
}

async function removeAllDryRunSelectedRepos(form: HTMLFormElement, allowDryRun = false) {
  const selectedReposElement = <HTMLInputElement>document.getElementById('selected_repo_ids')
  if (!selectedReposElement) {
    return
  }

  const selectedReposArray = JSON.parse(selectedReposElement.value)
  const selectedReposSet = new Set(selectedReposArray)
  selectedReposSet.clear()

  selectedReposElement.value = JSON.stringify(Array.from(selectedReposSet))

  const dryRunButton = document.querySelector<HTMLElement>('.js-org-repo-selector-dialog-dry-run-button')
  if (!dryRunButton) {
    return
  }

  if (allowDryRun) {
    dryRunButton.removeAttribute('disabled')
  } else {
    dryRunButton.setAttribute('disabled', 'true')
  }

  const formData = new FormData(form)
  formData.append('selected_repo_ids', selectedReposElement.value)

  const response = await fetch(form.action, {
    method: form.method,
    body: formData,
    headers: {Accept: 'text/fragment+html'},
  })

  if (response.status >= 400) {
    // eslint-disable-next-line i18n-text/no-en
    const message = 'An unknown error occurred.'
    const template = document.querySelector<HTMLTemplateElement>('template.js-flash-template')!
    template.after(new TemplateInstance(template, {className: 'flash-error', message}))
  } else if (!allowDryRun) {
    const target = <HTMLElement>document.querySelector('.js-dry-run-selected-repos')
    const partial = parseHTML(document, await response.text())
    target.replaceWith(partial)
  }
}

async function updateDryRunSelectedRepos(form: HTMLFormElement, repoId: number, addRepo: boolean) {
  const selectedReposElement = <HTMLInputElement>document.getElementById('selected_repo_ids')
  if (!selectedReposElement) {
    return
  }

  const dryRunButton = document.querySelector<HTMLElement>('.js-org-repo-selector-dialog-dry-run-button')
  if (!dryRunButton) {
    return
  }

  const selectedReposArray = JSON.parse(selectedReposElement.value)
  const selectedReposSet = new Set(selectedReposArray)

  if (addRepo) {
    if (selectedReposSet.size < getMaxDryRunSelectedRepos()) {
      selectedReposSet.add(repoId)
    }
  } else {
    selectedReposSet.delete(repoId)
  }

  selectedReposElement.value = JSON.stringify(Array.from(selectedReposSet))

  if (selectedReposSet.size > 0) {
    dryRunButton.removeAttribute('disabled')
  } else {
    dryRunButton.setAttribute('disabled', 'true')
  }

  const formData = new FormData(form)
  formData.append('selected_repo_ids', selectedReposElement.value)

  const response = await fetch(form.action, {
    method: form.method,
    body: formData,
    headers: {Accept: 'text/fragment+html'},
  })

  // Show an error message (if request fails)
  if (response.status >= 400) {
    // eslint-disable-next-line i18n-text/no-en
    const message = 'An unknown error occurred.'
    const template = document.querySelector<HTMLTemplateElement>('template.js-flash-template')!
    template.after(new TemplateInstance(template, {className: 'flash-error', message}))
  } else {
    const target = <HTMLElement>document.querySelector('.js-dry-run-selected-repos')
    const partial = parseHTML(document, await response.text())
    target.replaceWith(partial)
  }
}

// Only click works for now, seems there is no event listener for a submit from the details dialog class, but there is one for a click
on('click', '.js-remove-dry-run-repo-form', removeDryRunRepo)

// Add a selected repo to the list and clear the search box
on('auto-complete-change', '.js-dry-run-repo-autocomplete', function (event) {
  const autoComplete = event.target as AutocompleteElement
  if (!autoComplete.value) {
    return
  }

  // eslint-disable-next-line i18n-text/no-en
  if (autoComplete.value.includes('No repositories found.')) {
    autoComplete.value = ''
    return
  }

  const addForm = <HTMLFormElement>autoComplete.closest('form')
  if (!addForm) {
    return
  }

  updateDryRunSelectedRepos(addForm, parseInt(addForm.repo_id.value), true)

  autoComplete.value = ''
})

on('click', '.js-clear-selected-repositories-button', function () {
  const selectedReposForm = document.querySelector<HTMLFormElement>('.js-add-dry-run-repo-form')
  if (selectedReposForm) {
    removeAllDryRunSelectedRepos(selectedReposForm)
  }
})

observe('input[type="radio"][name="dry_run_repo_selection"]', element => {
  const radioButtonElement = element as HTMLInputElement
  const repoSelectionComponent = document.querySelector<HTMLElement>('.js-dry-run-repo-selection-component')

  if (!repoSelectionComponent || !radioButtonElement) {
    return
  }

  if (radioButtonElement.checked === true) {
    if (radioButtonElement.value === 'all_repos') {
      repoSelectionComponent.hidden = true
    } else if (radioButtonElement.value === 'selected_repos') {
      repoSelectionComponent.hidden = false
    }
  }
})

on('click', 'input[type="radio"][name="dry_run_repo_selection"]', function (event) {
  const target = event.currentTarget as HTMLInputElement
  const repoSelectionComponent = document.querySelector<HTMLElement>('.js-dry-run-repo-selection-component')

  if (!repoSelectionComponent) {
    return
  }

  if (target.value === 'all_repos') {
    repoSelectionComponent.hidden = true

    const selectedReposForm = <HTMLFormElement>repoSelectionComponent.querySelector('form')
    if (!selectedReposForm) {
      return
    }

    removeAllDryRunSelectedRepos(selectedReposForm, true)
  } else if (target.value === 'selected_repos') {
    repoSelectionComponent.hidden = false
    const dryRunButton = document.querySelector<HTMLElement>('.js-org-repo-selector-dialog-dry-run-button')
    if (!dryRunButton) {
      return
    }

    const selectedReposElement = <HTMLInputElement>document.getElementById('selected_repo_ids')
    if (!selectedReposElement) {
      return
    }

    repoSelectionComponent.children[1]!.childElementCount <= 1
      ? dryRunButton.setAttribute('disabled', 'true')
      : dryRunButton.removeAttribute('disabled')
  }
})

// Don't let users accidentally submit the form when you hit enter
onKey('keydown', '.js-dry-run-repo-autocomplete-input', function (event: KeyboardEvent) {
  // TODO: Refactor to use data-hotkey
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
  if (event.key === 'Enter') {
    event.preventDefault()
  }
  /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */
})

function getMaxDryRunSelectedRepos() {
  const maxDryRunSelectedReposElement = document.querySelector<HTMLElement>('.js-dry-run-selected-repos-max-count')!

  if (!maxDryRunSelectedReposElement) {
    return 10
  }

  const maxRepos = maxDryRunSelectedReposElement.textContent

  if (!maxRepos) {
    return 10
  }

  return parseInt(maxRepos)
}

function toggleWildcardsWarning(visible: boolean) {
  const warningElement = document.querySelector<HTMLElement>('.js-wildcards-warning')!
  if (!warningElement) return

  warningElement.hidden = !visible
}
